import axios from 'axios';

const papi = axios.create({
  baseURL: 'https://octanehq.org/projector',
});

papi.interceptors.request.use( 
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

export default papi;
