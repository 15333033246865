import React from 'react';

function StatsTable({ data, quarterlyData }) {
    return (
        <table className="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <th>Year</th>
                    <th>Period</th>
                    <th>Income</th>
                    <th>Expense</th>
                    <th>Net</th>
                    <th>Premium</th>
                    <th>Comparison</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(data).sort().reverse().map((year) => {
                    const yearData = data[year];
                    const monthsRows = Object.keys(yearData.months).sort().map((month, index) => {
                        const monthData = yearData.months[month];
                        return (
                            <tr key={month}>
                                {index === 0 && <td rowSpan={Object.keys(yearData.months).length + 1}>{year}</td>}
                                <td>{month}</td>
                                <td>{monthData.income}</td>
                                <td>{monthData.expense}</td>
                                <td>{monthData.net}</td>
                            </tr>
                        );
                    });

                    const annualRow = (
                        <tr key={year + "-annual"}>
                            <td>Annual</td>
                            <td>{yearData.annual_income}</td>
                            <td>{yearData.annual_expense}</td>
                            <td>{yearData.annual_net}</td>
                        </tr>
                    );

                    // Quarterly rows
                    const quarterlyRows = quarterlyData && quarterlyData[year]
                        ? Object.keys(quarterlyData[year].quarters).map((quarter) => {
                            const qData = quarterlyData[year].quarters[quarter];
                            return (
                                <tr key={year + "-Q" + quarter}>
                                    <td>{year}</td>
                                    <td>{"Q" + quarter}</td>
                                    <td>{qData.income}</td>
                                    <td>{qData.expense}</td>
                                    <td>{qData.net}</td>
                                    <td>{qData.premium.toFixed(2)}</td>
                                    <td>{qData.comparison ? qData.comparison.toFixed(2) + "%" : "N/A"}</td>
                                </tr>
                            );
                        })
                        : [];

                    return [...monthsRows, annualRow, ...quarterlyRows];
                })}
            </tbody>
        </table>
    );
}

export default StatsTable;
