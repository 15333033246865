import React, { useEffect, useState } from 'react';
import axios from 'axios';
import api from './api';
import Select from 'react-select';
import papi from './projectsapi';

function TransactionForm({ show, isNew, onClose, transactionId, setNotification }) {
  const [accounts, setAccounts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [transaction, setTransaction] = useState({
    amount: '',
    account_id: null,
    type_id: 1,
    note: '',
    date: '',
    categories: [],
    client_id: null  // Add this line if only one project can be linked
  });

  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);

  useEffect(() => {

    

    
    api.get('/accounts')
      .then(res => setAccounts(res.data))
      .catch(err => console.log(err));

    api.get('/categories')
      .then(res => setCategories(res.data))
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    // Fetch projects using the papi instance
    papi.get('/projects')
      .then(res => {
        const projectOptions = res.data.map(project => ({
          label: project.name,
          value: project.ID
        }));
        setProjects(projectOptions);
      })
      .catch(err => console.log(err));
  }, []);

  const handleProjectChange = selectedOption => {
    setSelectedProjects(selectedOption || []);
    const client_ids = selectedOption.map(option => option.value); // Capture all selected project IDs
    setTransaction(prev => ({ ...prev, client_id: client_ids.length ? client_ids[0] : null }));
  };
  

  useEffect(() => {
    if (!isNew && transactionId) {
      api.get(`/transactions/${transactionId}`)
        .then(res => {
          const data = res.data;
          const date = new Date(data.date);
          data.date = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
          if (data.client_id) {
            const selectedProject = projects.find(p => p.value === data.client_id);
            setSelectedProjects(selectedProject ? [selectedProject] : []);
          }
          setTransaction(res.data);
          //console.log('Transaction:', res.data);  // Add this line
          //console.log('Transaction Type ID:', res.data.type_id);  // And this line
        })
        .catch(err => console.log(err));
    } else {
      resetForm();
    }
  }, [isNew, transactionId]);

  function handleChange(e) {
    let newTransaction = { ...transaction, [e.target.name]: e.target.value };
    if (e.target.name === 'categories') {
        newTransaction = { ...transaction, [e.target.name]: Array.from(e.target.selectedOptions, option => option.value) };
    } else if (e.target.name === 'amount') {
        newTransaction.type_id = e.target.value.startsWith('-') ? '2' : '1';
        newTransaction.amount = e.target.value.startsWith('-') ? e.target.value.substring(1) : e.target.value;
    }
    setTransaction(newTransaction);
}



function handleSubmit(e) {
  e.preventDefault();
  const method = isNew ? 'post' : 'put';
  const url = isNew ? '/transactions' : `/transactions/${transactionId}`;

  // `transaction` already includes client_id if the handlers are set up correctly
  api[method](url, { ...transaction, categories: transaction.categories.map(Number) })
    .then(res => {
      setNotification(`Transaction ${isNew ? 'added' : 'updated'} successfully!`);
      resetForm();
      onClose();
    })
    .catch(err => {
      console.log(err);
      console.log(err.response.data);
      setNotification(`There was an error ${isNew ? 'adding' : 'updating'} the transaction.`);
    });
}


  function resetForm() {
    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;

    setTransaction({
      amount: '',
      account_id: null,
      type_id: 1,
      note: '',
      date: formattedDate,
      categories: []
    });
  }

  return (
    <div className={`offcanvas offcanvas-end ${show ? 'show' : ''}`} tabIndex="-1" id="offcanvasEdit" aria-labelledby="offcanvasEditLabel">
      {console.log('Rendering form with transactionId:', transactionId, 'and transaction:', transaction)}
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasEditLabel">{isNew ? 'Add Transaction' : 'Edit Transaction'}</h5>
        <button type="button" className="btn-close text-reset" onClick={onClose} aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="form-label">Amount</label>
            <input 
              type="text" 
              name="amount" 
              className="form-control" 
              value={transaction.type_id == '2' ? '-' + Math.abs(transaction.amount) : transaction.amount} 
              onChange={handleChange} 
            />
            <p>Type ID: {transaction.type_id ? (transaction.type_id == '1' ? 'Income' : 'Expense') : 'Fetching...'}</p>
            {console.log('Transaction type:', transaction.type_id)}
          </div>
          <div className="mb-3">
            <label className="form-label">Account</label>
            <select name="account_id" className="form-select" value={transaction.account_id || ''} onChange={handleChange}>
              <option disabled value="">Select account</option>
              {accounts.map((account) => (
                <option key={account.id} value={account.id}>{account.name}</option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">Categories</label>
            <select multiple name="categories" className="form-select" value={transaction.categories} onChange={handleChange}>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>{category.name}</option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">Date</label>
            <input type="date" name="date" className="form-control" value={transaction.date} onChange={handleChange} />
          </div>
          <div className="mb-3">
            <label className="form-label">Note</label>
            <input type="text" name="note" className="form-control" value={transaction.note} onChange={handleChange} />
          </div>

                    {/* Project Select Field */}
                    <div className="mb-3">
            <label className="form-label">Project</label>
            <Select 
              isMulti
              options={projects} 
              value={selectedProjects} 
              onChange={handleProjectChange} 
            />
          </div>

          <button type="submit" className="btn btn-primary">{isNew ? 'Add' : 'Update'}</button>
        </form>
      </div>
    </div>
  );
}

export default TransactionForm;
