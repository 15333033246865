import React, { useState } from 'react';

function ExchangeTable({ exchanges, onEdit, onDelete }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50); // Set the number of items per page

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      onDelete(id);
    }
  }

  const sortedData = [...exchanges].sort((a, b) => new Date(b.date) - new Date(a.date));

  // Pagination logic
  const indexOfFirstItem = (currentPage - 1) * itemsPerPage;
  const indexOfLastItem = indexOfFirstItem + itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(sortedData.length / itemsPerPage);
  const pageNumbers = Array.from({length: totalPages}, (_, i) => i + 1);

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">In Amount</th>
            <th scope="col">Out Amount</th>
            <th scope="col">In Account</th>
            <th scope="col">Out Account</th>
            <th scope="col">Date</th>
            <th scope="col">Note</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => {
            // Adding date formatting
            console.log(item); 
            let date = new Date(item.Date);
            let formattedDate = date.toDateString();

            return (
              <tr key={index}>
                <td>{item.InAmount}</td>
                <td>-{item.OutAmount}</td>
                <td>{item.in_account_name}</td>
                <td>{item.out_account_name}</td>
                <td>{formattedDate}</td>  {/* Here we use the formatted date */}
                <td>{item.Note}</td>
                <td>
                  <button className="btn btn-primary" onClick={() => onEdit(item)}>Edit</button>
                  <button className="btn btn-danger" onClick={() => handleDelete(item.ID)}>Delete</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div>
        {pageNumbers.map(number => (
          <button key={number} onClick={() => paginate(number)} className={currentPage === number ? 'selected' : ''}>
            {number}
          </button>
        ))}
      </div>
    </div>
  )
}

export default ExchangeTable;
