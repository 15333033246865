import React, { useEffect, useState } from 'react';
import axios from 'axios';
import api from './api';


function ExchangeForm({ show, isNew, onClose, exchangeId, setNotification }) {
  const [accounts, setAccounts] = useState([]);
  const [exchange, setExchange] = useState({
    InAmount: '',
    OutAmount: '',
    InAccountID: null,
    OutAccountID: null,
    Date: '',
    Note: '',
  });

  useEffect(() => {
    api.get('/accounts')
      .then(res => setAccounts(res.data))
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    console.log(exchangeId);
    if (!isNew && exchangeId) {
      api.get(`/exchange/${exchangeId}`)
        .then(res => {
          console.log(res.data);
          const data = res.data;
          const date = new Date(data.Date);
          data.Date = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
          setExchange(data);
        })
        .catch(err => console.log(err));
    } else {
      resetForm();
    }
  }, [isNew, exchangeId]);

  function handleChange(e) {
    const newExchange = { ...exchange, [e.target.name]: e.target.value };
    setExchange(newExchange);
  }
  

  function handleSubmit(e) {
    e.preventDefault();
    const method = isNew ? 'post' : 'put';
    const url = isNew ? '/exchange' : `/exchange/${exchangeId}`;
  
    // convert field names
    let data = Object.fromEntries(
      Object.entries(exchange).map(([k, v]) => 
        [k.charAt(0).toUpperCase() + k.slice(1), v]
      )
    );

    api[method](url, data)
      .then(res => {
        setNotification(`Exchange ${isNew ? 'added' : 'updated'} successfully!`);
        resetForm();
        onClose();
      })
      .catch(err => {
        console.log(err);
        console.log(err.response.data);
        setNotification(`There was an error ${isNew ? 'adding' : 'updating'} the exchange.`);
      });
  }

  

  function resetForm() {
    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
  
    setExchange({
      InAmount: '',
      OutAmount: '',
      InAccountID: null,
      OutAccountID: null,
      Date: formattedDate,
      Note: '',
    });
  }

  return (
    <div className={`offcanvas offcanvas-end ${show ? 'show' : ''}`} tabIndex="-1" id="offcanvasEdit" aria-labelledby="offcanvasEditLabel">
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasEditLabel">{isNew ? 'Add Exchange' : 'Edit Exchange'}</h5>
        <button type="button" className="btn-close text-reset" onClick={onClose} aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
      <form onSubmit={handleSubmit}>
  <div className="mb-3">
      <label className="form-label">Amount In</label>
      <input type="number" name="InAmount" className="form-control" value={exchange.InAmount} onChange={handleChange} />
  </div>
  <div className="mb-3">
      <label className="form-label">Amount Out</label>
      <input type="number" name="OutAmount" className="form-control" value={exchange.OutAmount} onChange={handleChange} />
  </div>
  <div className="mb-3">
      <label className="form-label">In Account</label>
      <select name="InAccountID" className="form-select" value={exchange.InAccountID || ''} onChange={handleChange}>
          <option disabled value="">Select In Account</option>
          {accounts.map((account) => (
              <option key={account.id} value={account.id}>{account.name}</option>
          ))}
      </select>
  </div>
  <div className="mb-3">
      <label className="form-label">Out Account</label>
      <select name="OutAccountID" className="form-select" value={exchange.OutAccountID || ''} onChange={handleChange}>
          <option disabled value="">Select Out Account</option>
          {accounts.map((account) => (
              <option key={account.id} value={account.id}>{account.name}</option>
          ))}
      </select>
  </div>
  <div className="mb-3">
      <label className="form-label">Date</label>
      <input type="date" name="Date" className="form-control" value={exchange.Date} onChange={handleChange} />
  </div>
  <div className="mb-3">
      <label className="form-label">Note</label>
      <input type="text" name="Note" className="form-control" value={exchange.Note} onChange={handleChange} />
  </div>
  <button type="submit" className="btn btn-primary">{isNew ? 'Add' : 'Update'}</button>
</form>

      </div>
    </div>
  );
}

export default ExchangeForm;
