import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ExchangeTable from './ExchangeTable';
import ExchangeForm from './ExchangeForm'; // you will create this component later
import Notification from './Notification';
import api from './api';

function Exchange() {
  const [exchanges, setExchanges] = useState([]);
  const [selectedExchangeId, setSelectedExchangeId] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isNewExchange, setIsNewExchange] = useState(false);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    api.get('/exchange')
    .then(res => {
      console.log(res.data); // add this line
      setExchanges(res.data);
    })
    .catch(err => console.error("An error occurred while fetching exchanges: ", err));
  
  }, []);
  

  const handleEdit = (exchange) => {
    setIsNewExchange(false);
    setSelectedExchangeId(exchange.ID);
    console.log('Exchange ID is: ' + exchange.ID);
    setShowSidebar(true);
  }

  const handleNewExchange = () => {
    setIsNewExchange(true);
    setSelectedExchangeId(null);
    setShowSidebar(true);
  }

  const handleDelete = (exchangeId) => {
    api.delete(`/exchange/${exchangeId}`)
      .then(res => {
        setExchanges(exchanges.filter(exchange => exchange.id !== exchangeId));
        setNotification('Exchange deleted successfully.');
      })
      .catch(err => {
        console.log(err);
        setNotification('An error occurred while deleting the exchange.');
      });
  }

  const handleSubmit = (exchange) => {
    if(isNewExchange) {
      // Add new exchange
      api.post('/exchange', exchange)
        .then(res => {
          setExchanges([res.data, ...exchanges]); 
          setShowSidebar(false);
        })
        .catch(err => console.log(err));
    } else {
      // Update existing exchange
    }
  }

  return (
    <div className="exchange-container">
        <h2>Exchange</h2>
        <button className="btn btn-primary" onClick={handleNewExchange}>Add Exchange</button>
        <ExchangeTable exchanges={exchanges} onEdit={handleEdit} onDelete={handleDelete}/>
        <ExchangeForm 
          show={showSidebar} 
          exchangeId={selectedExchangeId} 
          isNew={isNewExchange}
          onSubmit={handleSubmit}
          onClose={() => setShowSidebar(false)}
          setNotification={setNotification}  
        />
        {notification && <Notification message={notification} onDismiss={() => setNotification(null)} />}
    </div>
  );
}

export default Exchange;
