import React, { useState, useEffect } from 'react';

function TransactionsTable({ transactions, onEdit, onDelete }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50); // Set the number of items per page

  const renderAmount = (amount, type_id) => {
    let style;
    let displayAmount;

    if (type_id === 1) {
      style = { fontWeight: 'bold', color: 'green' };
    } else if (type_id === 2) {
      style = { color: 'red' };
      displayAmount = `- ${amount}`;
    }

    return <span style={style}>{displayAmount || amount}</span>
  }

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      onDelete(id);
    }
  }

  const sortedTransactions = [...transactions].sort((a, b) => new Date(b.date) - new Date(a.date));

  // Pagination logic
  const indexOfFirstTransaction = (currentPage - 1) * itemsPerPage;
  const indexOfLastTransaction = indexOfFirstTransaction + itemsPerPage;
  const currentTransactions = sortedTransactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(sortedTransactions.length / itemsPerPage);
  const pageNumbers = Array.from({length: totalPages}, (_, i) => i + 1);

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Amount</th>
            <th scope="col">Account Name</th>
            <th scope="col">USD Amount</th>
            <th scope="col">Date</th>
            <th scope="col">Note</th>
            <th scope="col">Categories</th>
            <th scope="col">Type ID</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentTransactions.map((transaction, index) => {
            // Adding date formatting
            let date = new Date(transaction.date);
            let formattedDate = date.toISOString().split('T')[0]; // Format as YYYY-MM-DD

            return (
              <tr key={index}>
                <td>{renderAmount(transaction.amount, transaction.type_id)} {transaction.currency_name}</td>
                <td>{transaction.account_name}</td>
                <td>{transaction.amount_usd.toFixed(2)} USD</td>
                <td>{formattedDate}</td>  {/* Here we use the formatted date */}
                <td>{transaction.note}</td>
                <td>{transaction.categories.join(', ')}</td>
                <td>{transaction.type_id}</td>
                <td>{transaction.id}</td>
                <td>
                  <button className="btn btn-primary" onClick={() => onEdit(transaction)}>Edit</button>
                  <button className="btn btn-danger" onClick={() => handleDelete(transaction.id)}>Delete</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div>
        {pageNumbers.map(number => (
          <button key={number} onClick={() => paginate(number)} className={currentPage === number ? 'selected' : ''}>
            {number}
          </button>
        ))}
      </div>
    </div>
  )
}

export default TransactionsTable;
  