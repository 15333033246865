// Totals.js
import React, { useState, useEffect } from 'react';
import api, { adminAuth } from '../api';

function Totals() {
  const [totals, setTotals] = useState([]);

  useEffect(() => {
    const fetchTotals = async () => {
      try {
        const response = await api.get('/totals', { auth: adminAuth });  // Replace with the actual endpoint for getting account totals
        setTotals(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTotals();
  }, []);

  return (
    <div className='container-fluid'>
      {totals.map((total) => (
        <div className="single-totals" key={total.accountId}>
          {total.accountName}: {total.totalAmount} {total.currency}
        </div>
      ))}
    </div>
  );
}

export default Totals;
