import React, { useState, useEffect } from 'react';
import api from './api';
import BarChart from './BarChart';
import StatsTable from './StatsTable';

function calculateQuarterlyData(data) {
    const quarterlyData = {};
    for (const year in data) {
        quarterlyData[year] = { quarters: {} };
        for (let quarter = 1; quarter <= 4; quarter++) {
            let totalIncome = 0, totalExpense = 0;
            for (let month = 3 * quarter - 2; month <= 3 * quarter; month++) {
                const monthKey = String(month).padStart(2, '0'); // Ensuring month is two digits
                if (data[year].months[monthKey]) {
                    totalIncome += data[year].months[monthKey].income;
                    totalExpense += data[year].months[monthKey].expense;
                }
            }
            quarterlyData[year].quarters[quarter] = {
                income: totalIncome,
                expense: totalExpense,
                net: totalIncome - totalExpense,
                premium: (totalIncome - totalExpense) * 0.05 // 5% premium
            };
        }
    }
    return quarterlyData;
}


function calculateQuarterComparison(quarterlyData) {
    for (const year in quarterlyData) {
        for (let quarter = 1; quarter <= 4; quarter++) {
            const currentNet = quarterlyData[year].quarters[quarter].net;
            let prevNet = null;

            if (quarter > 1) {
                prevNet = quarterlyData[year].quarters[quarter - 1].net;
            } else if (parseInt(year) > Math.min(...Object.keys(quarterlyData).map(y => parseInt(y)))) {
                const prevYear = (parseInt(year) - 1).toString();
                prevNet = quarterlyData[prevYear].quarters[4].net;
            }

            quarterlyData[year].quarters[quarter].comparison = prevNet !== null 
                ? ((currentNet - prevNet) / prevNet) * 100 
                : null;
        }
    }
    return quarterlyData;
}


function Stats() {
    const [categoryData, setCategoryData] = useState({});
    const [totalData, setTotalData] = useState({});
    const [quarterlyData, setQuarterlyData] = useState({});

    useEffect(() => {
        const fetchCategoryData = async () => {
            const response = await api.get('/categorytotals');
            setCategoryData(response.data);
        };

        const fetchTotalData = async () => {
            const response = await api.get('/totalstats');
            const fetchedTotalData = response.data;

            setTotalData(fetchedTotalData);

            const calculatedQuarterlyData = calculateQuarterlyData(fetchedTotalData);
            calculateQuarterComparison(calculatedQuarterlyData);
            setQuarterlyData(calculatedQuarterlyData);
        };

        fetchCategoryData();
        fetchTotalData();
    }, []);

    return (
        <div>
            <h3>Income</h3>
            {categoryData[1] && <BarChart data={categoryData[1]} chartId="incomeChart" />}
            <h3>Expense</h3>
            {categoryData[2] && <BarChart data={categoryData[2]} chartId="expenseChart" />}
            <h3>Stats by Year and Month</h3>
            <StatsTable data={totalData} />
            <h3>Stats by Year and Quarter</h3>
            <StatsTable data={totalData} quarterlyData={quarterlyData} />
        </div>
    );
}

export default Stats;
