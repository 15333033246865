// api.js
import axios from 'axios';

export const adminAuth = {
  username: 'admin',
  password: 'superapipassword'
};

const api = axios.create({
  baseURL: 'https://bbapi.octanehq.org',
  auth: adminAuth
});

export const setAuthHeaders = (token) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export default api;
