import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TransactionsTable from './TransactionsTable';
import TransactionForm from './TransactionForm';
import Notification from './Notification';
import api from './api';

function Transactions() {
  const [transactions, setTransactions] = useState([]);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isNewTransaction, setIsNewTransaction] = useState(false);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    api.get('/transactions')
      .then(res => setTransactions(res.data))
      .catch(err => console.log(err));
  }, []);

  const handleEdit = (transaction) => {
    setIsNewTransaction(false);
    setSelectedTransactionId(transaction.id);
    setShowSidebar(true);
  }

  const handleNewTransaction = () => {
    setIsNewTransaction(true);
    setSelectedTransactionId(null);
    setShowSidebar(true);
  }

  const handleDelete = (transactionId) => {
    api.delete(`/transactions/${transactionId}`)
      .then(res => {
        setTransactions(transactions.filter(transaction => transaction.id !== transactionId));
        setNotification('Transaction deleted successfully.');
      })
      .catch(err => {
        console.log(err);
        setNotification('An error occurred while deleting the transaction.');
      });
  }

  const handleSubmit = (transaction) => {  // transaction parameter added here
    if(isNewTransaction) {
      // Add new transaction
      api.post('/transactions', transaction)
        .then(res => {
          setTransactions([res.data, ...transactions]); 
          setShowSidebar(false);
        })
        .catch(err => console.log(err));
    } else {
      // Update existing transaction
    }
  }

  return (
    <div className="transactions-container">
        <h2>Transactions</h2>
        <button className="btn btn-primary" onClick={handleNewTransaction}>Add Transaction</button>
        <TransactionsTable transactions={transactions} onEdit={handleEdit} onDelete={handleDelete}/>
        <TransactionForm 
          show={showSidebar} 
          transactionId={selectedTransactionId} 
          isNew={isNewTransaction}
          onSubmit={handleSubmit} // only handleSubmit here, without handleInputChange
          onClose={() => setShowSidebar(false)}
          setNotification={setNotification}  // Pass the setNotification function as a prop
        />
        {notification && <Notification message={notification} onDismiss={() => setNotification(null)} />}
    </div>
  );
}

export default Transactions;
