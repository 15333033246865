import React from 'react';
import Chart from 'chart.js';

// Function to generate a random RGB color
const generateRandomColor = () => {
    return `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.2)`;
}

function BarChart({ data, chartId }) {
    React.useEffect(() => {
        if (Object.keys(data).length) {
            const ctx = document.getElementById(chartId).getContext('2d');

            const backgroundColors = Array(Object.keys(data).length).fill().map(() => generateRandomColor());

            new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: Object.keys(data),
                    datasets: [{
                        label: 'Category Totals',
                        data: Object.values(data),
                        backgroundColor: backgroundColors,
                    }]
                },
                options: {
                    responsive: true,
                    plugins: {
                        tooltip: {
                            callbacks: {
                                title: (context) => {
                                    return context[0].label;
                                },
                                label: (context) => {
                                    return `$ ${context.parsed.y}`;
                                }
                            }
                        }
                    }
                }
            });
        }
    }, [data, chartId]);

    return (
        <canvas id={chartId} width="1000" height="300"></canvas>
    );
}

export default BarChart;
