import React, { useEffect, useState } from 'react';

function Notification({ message, onDismiss }) {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false);
      onDismiss();
    }, 3000);
    return () => clearTimeout(timeout);
  }, [onDismiss]);

  if (!show) return null;

  return (
    <div style={{
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      backgroundColor: '#44c767',
      color: 'white',
      padding: '10px',
      borderRadius: '5px',
      zIndex: 1000
    }}>
      {message}
    </div>
  );
}

export default Notification;
