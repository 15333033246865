// Header.js
import React from 'react';
import Totals from './Totals';

function Header() {
  return (
    <><nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <a className="navbar-brand" href="#">Octane Books</a>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="/transactions">Transactions</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/exchange">Exchange</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/stats">Stats</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    
    <div className="navbar navbar-expand-lg">
        <Totals />
    </div>
    
    </>
  );
}

export default Header;
